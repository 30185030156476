exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-compare-domains-tsx": () => import("./../../../src/pages/compare-domains.tsx" /* webpackChunkName: "component---src-pages-compare-domains-tsx" */),
  "component---src-pages-compare-frameworks-tsx": () => import("./../../../src/pages/compare-frameworks.tsx" /* webpackChunkName: "component---src-pages-compare-frameworks-tsx" */),
  "component---src-pages-compare-terms-tsx": () => import("./../../../src/pages/compare-terms.tsx" /* webpackChunkName: "component---src-pages-compare-terms-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-force-directed-graph-tsx": () => import("./../../../src/pages/force-directed-graph.tsx" /* webpackChunkName: "component---src-pages-force-directed-graph-tsx" */),
  "component---src-pages-frameworks-tsx": () => import("./../../../src/pages/frameworks.tsx" /* webpackChunkName: "component---src-pages-frameworks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-methods-tsx": () => import("./../../../src/pages/methods.tsx" /* webpackChunkName: "component---src-pages-methods-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-state-standards-alignment-tsx": () => import("./../../../src/pages/state-standards/alignment.tsx" /* webpackChunkName: "component---src-pages-state-standards-alignment-tsx" */),
  "component---src-pages-state-standards-developmental-progression-tsx": () => import("./../../../src/pages/state-standards/developmental-progression.tsx" /* webpackChunkName: "component---src-pages-state-standards-developmental-progression-tsx" */),
  "component---src-pages-state-standards-index-tsx": () => import("./../../../src/pages/state-standards/index.tsx" /* webpackChunkName: "component---src-pages-state-standards-index-tsx" */),
  "component---src-pages-state-standards-tools-tsx": () => import("./../../../src/pages/state-standards/tools.tsx" /* webpackChunkName: "component---src-pages-state-standards-tools-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-visual-tools-tsx": () => import("./../../../src/pages/visual-tools.tsx" /* webpackChunkName: "component---src-pages-visual-tools-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-framework-terms-tsx": () => import("./../../../src/templates/framework/terms.tsx" /* webpackChunkName: "component---src-templates-framework-terms-tsx" */),
  "component---src-templates-framework-tsx": () => import("./../../../src/templates/framework.tsx" /* webpackChunkName: "component---src-templates-framework-tsx" */),
  "component---src-templates-term-tsx": () => import("./../../../src/templates/term.tsx" /* webpackChunkName: "component---src-templates-term-tsx" */),
  "component---src-templates-terms-named-tsx": () => import("./../../../src/templates/terms-named.tsx" /* webpackChunkName: "component---src-templates-terms-named-tsx" */)
}

